import React from "react"
import { Link } from "gatsby"
import Tail from "./tail"

const Footer = () => {
    return (
        <section className="container-fluid bg-light footer-wrapper">
            <footer>
                <div className="container">
                    <div className="row pb-3 pt-5">
                        <div className="col-12 col-md-8">
                            <div className="row">
                                <div className="col-6 col-sm mb-3">
                                    <h5>Products</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2"><Link to="/products/pillows"><span className="nav-link p-0 text-muted">Pillows</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/mattresses"><span className="nav-link p-0 text-muted">Mattresses</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/protectors"><span className="nav-link p-0 text-muted">Mattress Protectors</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/eyemasks"><span className="nav-link p-0 text-muted">Eye Masks</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/bedspread"><span className="nav-link p-0 text-muted">Bedspread</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/futon"><span className="nav-link p-0 text-muted">Futon Mattress</span></Link></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm mb-3">
                                    <h5>Support</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2"><Link to="/faq"><span className="nav-link p-0 text-muted">FAQs</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/returns"><span className="nav-link p-0 text-muted">Returns & Exchanges</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/warranty"><span className="nav-link p-0 text-muted">Warranty & Trial</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/privacy"><span className="nav-link p-0 text-muted">Privacy Policy</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/terms"><span className="nav-link p-0 text-muted">Terms & Conditions</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/sitemap"><span className="nav-link p-0 text-muted">Sitemap</span></Link></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm mb-3">
                                    <h5>About</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2"><Link to="/contact"><span className="nav-link p-0 text-muted">Get In Touch</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/"><span className="nav-link p-0 text-muted">Home</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/features"><span className="nav-link p-0 text-muted">Mattress Features</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/about"><span className="nav-link p-0 text-muted">Know Us</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/delivery"><span className="nav-link p-0 text-muted">Shipping & Delivery</span></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <hr />
                            <div className="text-center d-none d-md-block">
                                <Tail />
                            </div>
                        </div>
                        <div className="col col-md-4">
                            <div className="row">
                                <div className="col text-center mb-0 mb-md-3 company-info">
                                    <div>
                                        <img className="img-fluid footer-logo" src="/logo.png" alt="" /><sup style={{ fontSize: '8px', top: '-80px', right: '-80px' }}>&nbsp;TM</sup>
                                    </div>
                                    <hr />
                                    <div className="text-md-start">
                                        <p>Reach out to us:<br /><span>Monday to Saturday 9 AM to 6 PM</span></p>
                                        <p><i className="bi bi-telephone"></i> <a href="tel:+919744488388" style={{ color: '#fff' }}>+91 9744 488 388</a></p>
                                        <p><i className="bi bi-envelope"></i> <a href="mailto:info@plumesmattress.com" style={{ color: '#fff' }}>info@plumesmattress.com</a></p>
                                        <div className="social-media mt-2">
                                            <h5 className="mt-1">Follow us on</h5>
                                            <i className="bi bi-facebook"></i>
                                            <i className="bi bi-instagram"></i>
                                            <a href="https://x.com/plumesmattress" target="_blank"><i className="bi bi-twitter-x"></i></a>
                                            <a href="https://www.youtube.com/@PlumesMattress" target="_blank"><i className="bi bi-youtube"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center d-md-none d-block">
                    <Tail />
                </div>
            </footer>
        </section>
    )
}

export default Footer