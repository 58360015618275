import React from "react"

const year = new Date().getFullYear()

const Tail = () => {

    return (
        <div>
            <p>© {year}. All Rights Reserved | AS Sleep Makers.</p>
        </div>
    )
}

export default Tail