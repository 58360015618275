import React, { useState, useEffect } from "react"

const Announcement = () => {
  const texts = [
    <strong>New Year Sale!</strong>,
    <>
      Offer is live until <strong>31st January!</strong>
    </>,
    <>
      Get pillows for up to <strong>50% OFF</strong>!
    </>,
    <>
      <strong>Hurry!</strong> Limited stock available!
    </>
  ]

  const [currentIndex, setCurrentIndex] = useState(0)
  const [animate, setAnimate] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(false)
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length)
        setAnimate(true)
      }, 500)
    }, 4000)

    return () => clearInterval(interval)
  }, [texts.length])

  return (
    <div className="announcement-bar">
      <span className={`text ${animate ? "fade-slide" : ""}`}>
        {texts[currentIndex]}
      </span>
    </div>
  )
}

export default Announcement
