import React from "react"

const CartItem = ({item, index, updateCart}) => {
    return (
        <div className="cart-item">
            <i className="remove-item bi bi-trash mr-3" onClick={()=> updateCart({index:index, quantity:0})} onKeyDown={()=> updateCart({index:index, quantity:0})} role="presentation"></i>
            <h4>The <span className="brand">plumes</span>™ {item.product.category}</h4>
            <h6 className="text-primary">{item.product.category === 'Pillow' ? item.product.types[item.type].material : item.product.name}
            {(item.product.category === 'Mattress' || item.product.category === 'Futon Mattress') && <> | <span style={{fontSize: '12px', color: '#333'}}>{item.selectedSize[1]} Inch X {item.selectedSize[0]} Inch X {item.thick} Inch ({item.size})</span></>}
            {(item.product.category === 'Protector' || item.product.category === 'Bedspread') && <> | <span style={{fontSize: '12px', color: '#333'}}>{item.selectedSize[1]} Inch X {item.selectedSize[0]} Inch</span> | <span style={{fontSize: '12px', color: '#333'}}>{item.color.name}</span></>}
            {item.product.category === 'Pillow' && <> | <span style={{fontSize: '12px', color: '#333'}}>{item.product.types[item.type].size[0]}cm X {item.product.types[item.type].size[1]}cm</span></>}
            {item.product.category === 'Eye Mask' && <> | <span style={{fontSize: '12px', color: '#333'}}>{item.color.name}</span></>}
            </h6>
            
            <div className="d-flex align-items-center">
                <i className={((item.quantity === 1 || item.quantity === '1') ? 'disabled ':'') + 'bi bi-dash-circle-fill text-info h5'} onClick={()=> updateCart({index:index, quantity:parseInt((item.quantity === 1 || item.quantity === '1') ? 2:item.quantity)-1})} role="presentation"></i>
                <span className="h5" style={{width: '50px', textAlign: 'center'}}>{item.quantity}</span>
                <i className="bi bi-plus-circle-fill text-info h5" onClick={()=> updateCart({index:index, quantity:parseInt(item.quantity)+1})} onKeyDown={()=> updateCart({index:index, quantity:parseInt(item.quantity)+1})} role="presentation"></i><h4 className="text-info mx-2"><span style={{color: '#ccc'}}>|</span> ₹{(item.price * parseInt(item.quantity)).toFixed(2)}</h4></div>
        </div>
    )
}

export default CartItem